@import url('https://fonts.googleapis.com/css2?family=Black+Ops+One&family=Caveat&family=Montserrat&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,400;1,500&display=swap');
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
}

body{
    font-family: "Poppins", sans-serif;
}

.z-index-11{
    z-index: 11;
}

.pad-0{
    padding: 0 !important;
}
.pad-tb-50{
    padding: 50px 0;
}

img{
    width: 100%;
    height: auto;
}

.navbar-brand{
    img{
        width: 250px;
        height: auto;
    }
}

.collapse.navbar-collapse{
    width: 100%;
    justify-content: space-between;
}

h3{
    font-size: 40px;
    font-weight: 600; 
    color: #231f20;
}
p{
    color: #000;
    font-size: 18px;
    line-height: 1.8;
}

button{
    padding: 10px 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background-color: #231f20;
    font-size: 18px;
    color: #fff;
    a{
        color: #fff;
    }
}
button:hover{
    background-color: #f65020;
    a{
        color: #000;
        text-decoration: none;
        font-weight: 600;
    }
}

.video-section{
    position: relative;
    padding: 50px 0;
    video{
        width: 100%;
    }
    .video-content{
        position: absolute;
        top: 50%;
        left: 15%;
        h1{
            font-size: 38px;
            font-weight: 600;
            color: #fff;
            background: rgba(0, 0, 0, 0.5);
            padding: 10px;
            font-style: italic;
        }
    }
}

//Navbar
.navbar{
    box-shadow: 0 1px 5px 0 #ed5815;
    background-color: #f2efed;
    width: 100%;
    .navbar-nav{
        .nav-item{
            margin-right: 15px;
            border: 2px solid transparent;
            a{
                font-weight: 500;
                font-size: 18px;
                border: none;
            }
        }
        .nav-item:hover, .active-btn{
            border: 2px solid #ed5815;
            border-radius: 10px;
            background: #ccc;
            box-shadow: 0 1px 5px 0 #ed5815;
            a{
                font-weight: 600;
            }
        }
    }
}

//footer
.footer{
    padding: 50px;
    background-color: #231f20;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .footer-items{
        a{
            font-size: 30px;
            color: #fff;
            padding-right: 15px;
            img{
                width: 48px;
            }
        }
    }
    p{
        color: #fff;
    }
}

.home-about-section{
    padding: 75px 0;
}

.design-silder-section{
    padding: 50px 0;
    .swiper{
        .swiper-button-prev, .swiper-button-next{
            color: #fff;
            font-weight: 600;
            background-color:#231f20;
            width: 40px;
            height: 40px;
            border-radius: 50px;
        }
        .swiper-button-prev::after, .swiper-button-next::after{
            font-size: 20px;
        }
        .swiper-pagination-fraction {
            color: #fff;
        }
    }
}

.contact-us{
    .ccs-left{
        .contact-form{
            padding: 25px;
            margin-top: 25px;
            box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
            input{
                width: 100%;
                padding: 5px 15px;
                font-size: 16px;
                border-radius: 10px;
                margin-bottom: 20px;
                border: 1px solid #ccc;
            }
            input:focus-visible{
                border: 2px solid #231f20;
                outline: 0;
            }
        }
    }
    .ccs-right{
        .add-name{
            display: flex;
            align-items: baseline;
            padding: 15px 0;
            svg{
                font-size: 20px;
                margin-right: 5px;
                fill: #231f20;
            }
            p{
                margin: 0;
            }
        }
    }
}

.sp-box{
    h4{
        padding: 10px 0;
        font-weight: 500;
        font-size: 22px;
    }
}

.about-header{
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    width: 100%;
    .abh-img{
        img{
            max-height: 450px;
        }
    }
}

.whatsapp-section{
    position: fixed;
    top: auto;
    right: 2%;
    z-index: 11;
    bottom: 7%;
    img{
        width: 80px;
    }
}

.interior-section{
    .video-responsive{
        padding-top: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        h2{
            font-size: 20px;
            text-align: center;
            margin-top: 10px;
            font-weight: 600;
        }
    }
}

.relative{
    position: relative;
}

.error{
    position: absolute;
    bottom: 4px;
    left: 0;
    color: red;
    font-size: 10px;
}

.success{
    color: rgb(55, 194, 55);
    font-size: 15px;
    position: absolute;
    bottom: 18%;
    left: 6%;
}

iframe{
    width: 100%;
}
//responsive css
@media screen and (max-width: 1199px) {
    h3{
        font-size: 30px;
        font-weight: 600; 
        color: #231f20;
    }
    p{
        color: #000;
        font-size: 15px;
        line-height: 1.8;
    }
    .sp-box{
        h4{
            font-size: 20px;
        }
    }
    .navbar-brand{
        img{
            width: 170px;
        }
    }
    .navbar{
        .navbar-nav{
            .nav-item{
                a{
                    font-size: 15px;
                }
            }
        }
    }
    button{
        font-size: 15px;
        padding: 8px 15px;
    }
    .footer{
        .footer-items{
            a{
                img{
                    width: 38px;
                }
            }
        }
    }
    .whatsapp-section{
        img{
            width: 55px;
        }
    }
    .video-section {
        .video-content {
            position: absolute;
            top: 50%;
            left: 8%;
            h1{
                font-size: 35px;
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 991px){
    .navbar{
        .navbar-toggler{
            font-size: 15px;
            padding: 5px 8px;
            margin-right: 15px;
        }
    }
    .sp-box{
        padding-bottom: 25px;
    }
    .video-section {
        .video-content {
            position: absolute;
            top: 39%;
            left: 0%;    
            h1{
                font-size: 30px;
                text-align: center;
            }
        }
    }
    .footer{
        .footer-items{
            a{
                img{
                    width: 35px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px){
    .navbar{
        .navbar-toggler{
            font-size: 12px;
            padding: 5px 8px;
        }
    }
}